import { createAction, props } from '@ngrx/store';
import { User } from '@app/models/authorisation/user.class';
import { RoleAllocation } from '@app/models/authorisation/roleAllocation.class';

export const setUserName = createAction(
  '[User Component] UserName',
  props<{ fullName: string }>()
);

export const setUserPermissions = createAction(
  '[User Component] Permissions',
  props<{ allocations: RoleAllocation[] }>()
);

export const setUserInformation = createAction(
  '[User Component] AllInformation',
  props<{ user: User }>()
);
